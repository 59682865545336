import store from '@/store'
import router from '@/router'

export function getStoreCode() {
  if (process.env.VUE_APP_CHANNEL_CODE) {
    return process.env.VUE_APP_CHANNEL_CODE
  } else {
    const domain = new URL(window.location.href || document.location.href)
      .hostname
    const part1 = domain.split('.')[0]
    const part2 = part1.split('-')
    if (part2[1] !== undefined) {
      return part2[1]
    } else {
      return part2[0]
    }
  }
}
// 判斷正式或測試 happygo用
export function getEnv() {
  const domain = new URL(window.location.href || document.location.href)
    .hostname
  const part1 = domain.split('.')[0]
  if (part1.indexOf('-') !== -1 || part1 === domain) {
    return 'DEV'
  } else {
    return 'PROD'
  }
}

// 取得目前環境
export function getENVtype() {
  const urlInfo = new URL(window.location.href || document.location.href)
  const { hostname } = urlInfo

  if (hostname.startsWith('alex-') || hostname === 'localhost') {
    return 'dev'
  }

  if (hostname.startsWith('qa-')) {
    return 'qa'
  }

  return 'prod'
}

export function getApiUrl(nochannel) {
  // return 'https://test.opencube.tw/' + getStoreCode()
  const urlInfo = new URL(window.location.href || document.location.href)
  const domain = urlInfo.hostname
  const storeCode = nochannel ? '' : '/' + getStoreCode()
  if (domain === 'localhost' || domain.indexOf('192') !== -1) {
    return process.env.VUE_APP_BASE_API_DEV + storeCode
  } else {
    return (
      urlInfo.protocol +
      '//' +
      domain.replace(getStoreCode() + '.', 'api.') +
      storeCode
    )
  }
}

export function getImgUrl() {
  // return 'https://test.opencube.tw/' + getStoreCode()
  const urlInfo = new URL(window.location.href || document.location.href)
  const domain = urlInfo.hostname
  // const part1 = domain.split('.')[0]
  // const part2 = part1.split('-')
  if (domain === 'localhost' || domain.indexOf('192') !== -1) {
    return 'https://alex-img.3tet.com'
  } else {
    return (
      urlInfo.protocol + '//' + domain.replace(getStoreCode() + '.', 'img.')
    )
  }
}
export function getSSOUrl() {
  const partnername = getStoreCode().toLocaleUpperCase()
  const str = `VUE_APP_${partnername}_URL_` + getEnv()
  return process.env[str]
}

export function getHappyGoUrl() {
  const str = 'VUE_APP_HAPPYGO_URL_' + getEnv()
  return process.env[str]
}

export function getHappyGoSiteCoode() {
  const str = 'VUE_APP_HAPPYGO_SITE_CODE_' + getEnv()
  return process.env[str]
}

export function toErrorPage(message) {
  store.commit('SET_ERROR_MESSAGE', message)
  router.push({ path: '/error' }, () => {})
}

export function showNotice(message, func, close) {
  const data = {
    message: message,
    func: func,
    close: close === undefined ? true : close
  }
  store.commit('SET_NOTICE_MESSAGE', data)
}

export function showCusNotice(message, url, func, close) {
  const data = {
    message: message,
    url: url,
    func: func,
    close: close === undefined ? true : close
  }
  store.commit('SET_CUS_NOTICE', data)
}

export function showConfirm(message, func, cancelFunc, warning, btn) {
  if (typeof func === 'function') {
    const confirm = {
      message: message,
      func: func,
      cancelFunc: cancelFunc,
      warning: warning,
      btn: btn
    }
    store.commit('SET_CONFIRM_DIALOG', confirm)
  }
}

export function showFirstLoginConfirm(
  message,
  submessage,
  submessage2,
  func,
  cancelFunc
) {
  if (typeof func === 'function') {
    const confirm = {
      message: message,
      submessage: submessage,
      submessage2: submessage2,
      func: func,
      cancelFunc: cancelFunc
    }
    store.commit('SET_FIRSTLOGINCONFIRM_DIALOG', confirm)
  }
}

export function setCateClass(cate_id) {
  cate_id = cate_id + ''
  switch (cate_id) {
    case '1':
      return 'eating_item'
    case '2':
      return 'hotspring_color'
    case '3':
      return 'living_item'
    case '4':
      return 'spa_item'
    case '5':
      return 'amusement_item'
    case '6':
      return 'traffic_item'
    case '7':
      return 'wifi_sim_item'
    case '8':
      return 'travil_item'
    default:
      return 'allitem_color'
  }
}

export function happyGoTimeNotice() {
  var date = new Date()

  var minutes = date.getMinutes()
  var hour = date.getHours()

  if ((hour === 23 && minutes >= 50) || (hour === 0 && minutes <= 10)) {
    showNotice('提醒\r\n 23:50 ~ 00:10 無法使用Happygo點數')
  }
}

export function checkPlatform() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  if (/android/i.test(userAgent)) {
    return 'android'
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios'
  }

  return 'web'
}

export function getStoreappurl() {
  const str =
    'VUE_APP_' + getStoreCode().toLocaleUpperCase() + '_URL_' + getEnv()

  return process.env[str]
}

export function loadCSSbystore() {
  const file = document.createElement('link')
  file.rel = 'stylesheet'
  const domain = new URL(window.location.href || document.location.href)
    .hostname
  const part1 = domain.split('.')[0]
  const csssrc = 'mall_' + getStoreCode() + '.css' + '?v=' + new Date().getTime()
  if (part1.indexOf('-') !== -1 || part1 === domain) {
    if (part1.indexOf('qa-') !== -1) {
      file.href = 'https://qa-img.3tet.com/upload/assets/' + csssrc
    } else {
      file.href = 'https://alex-img.3tet.com/upload/assets/' + csssrc
    }
  } else {
    file.href = 'https://img.3tet.com/upload/assets/' + csssrc
  }
  document.head.appendChild(file)
  // load common css themes 載入共用
  const commonthemesfile = document.createElement('link')
  commonthemesfile.rel = 'stylesheet'
  commonthemesfile.href = '/Scripts/commonthemes.css' + '?v=' + new Date().getTime()
  document.head.appendChild(commonthemesfile)
}

// 設定 Title 資訊
export const getTitle = val => {
  const title =
    store.getters.shopInfo && store.getters.shopInfo.title
      ? `${store.getters.shopInfo.title}`
      : `3TET 電票通`
  if (!val) {
    document.title = title
  } else {
    document.title = `${val} | ${title}`
  }
}

function pad(v) {
  return v < 10 ? '0' + v : v
}
// get date formta yyyyMMddHHmmss
export const getDateFormat = d => {
  var year = d.getFullYear()
  var month = pad(d.getMonth() + 1)
  var day = pad(d.getDate())
  var hour = pad(d.getHours())
  var min = pad(d.getMinutes())
  var sec = pad(d.getSeconds())
  return year + month + day + hour + min + sec
}
