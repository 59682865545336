import Vue from 'vue'
import Router from 'vue-router'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import zh_TW from 'vee-validate/dist/locale/zh_TW'
import VeeValidate, { Validator } from 'vee-validate'
import { siteLogin, setupRefreshToken, userLogin } from '@/utils/user'
import { loginByUid } from '@/api/user'
import { getShopInfo } from '@/api/shop'
import { showNotice, getStoreCode, getTitle, loadCSSbystore } from '@/utils/tool'
import { gtmInit } from '@/utils/gtmtool'
import { initscriptbypartner } from '@/utils/initpartnerscript'
import '@/utils/filters'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'font-awesome/css/font-awesome.min.css'
import 'swiper/dist/css/swiper.css'
import '@/assets/custom.css'
import '@/assets/append.css'

// load the css file
loadCSSbystore()

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Validator.extend(`phone`, {
  // 驗證規則，符合規則通過，否則不通過 (規則為台灣電話號碼)
  validate: (value, ref) => {
    return /((?=(09))[0-9]{10})$/.test(value)
  }
})

Vue.use(VeeValidate, {
  classes: true,
  classNames: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  },
  i18nRootKey: 'validations', // customize the root path for validation messages.
  fieldsBagName: 'formFields',
  locale: 'zh_TW',
  dictionary: {
    zh_TW: zh_TW
  }
})

// 不能在iframe中
// if (window !== window.top) {
//   window.top.location.href = window.location.href
// }

// 需要登入的頁面判斷
router.beforeEach((to, from, next) => {
  // 設定 Title 資訊
  if (to.name === 'Index') {
    getTitle()
  } else if (to.name !== 'ProductCate' || to.name !== 'ProductDetail') {
    getTitle(to.meta.title)
  }
  if (to.meta) {
    if (
      to.meta.needLogin &&
      (!store.getters.authInfo.token || !store.getters.authInfo.third_verify)
    ) {
      // 沒token直接sso
      userLogin(to)
    }
    // else if (to.meta.needLogin && !store.getters.authInfo.third_verify) {
    //   userLogin(to)
    //   // 有token但需要otp
    //   // router.push({ path: '/user/otp' }).catch(() => {
    //   // })
    // }
  }
  // 攜帶 partner 參數
  if (from.query.partner && !to.query.partner) {
    to.query.partner = from.query.partner
    next(to)
  }
  // from sso login or to fail/finish
  if (
    VueCookies.get('partner_code') &&
    !to.query.partner &&
    !from.query.partner
  ) {
    to.query.partner = VueCookies.get('partner_code')
    next(to)
  }
  // 若使用者未關閉彈出直接返回,移除overflow 屬性
  if (to.name === 'ProductCate') {
    if (document.body.style.overflow === 'hidden') {
      document.body.style.overflow = ''
    }
  }
  next()
})

store.commit('SET_NOTICE_MESSAGE', null)
store.commit('SET_CUS_NOTICE', null)
store.commit('SET_LOADING', true)
store.commit('SET_LOAD_COMPLETED', true)

const host = new URL(window.location.href || document.location.href)

// 處理不同通路商後台需要預先載入的script檔案
initscriptbypartner()
// 偵測網址channel
// const channel = process.env.VUE_APP_CHANNEL_CODE ? process.env.VUE_APP_CHANNEL_CODE : host.hostname.split('.')[0]
// store.commit('SET_CHANNEL', channel)

// 呈現ui
new Vue({
  router,
  store,
  created() {
    // 偵測happygo app進來的uid
    const uid = host.searchParams.get('uid')
    if (uid && getStoreCode() === 'happygo') {
      // 使用happyGo uid登入3T
      loginByUid(uid)
        .then(response => {
          siteLogin(response, window.location.pathname)
        })
        .catch(() => {})
        .finally(() => {
          store.commit('SET_LOADING', false)
          // store.commit('SET_LOAD_COMPLETED', true)
        })
    } else {
      setupRefreshToken()
      store.commit('SET_LOADING', false)
      // store.commit('SET_LOAD_COMPLETED', true)
    }

    // partner code 7天
    const partner_code = host.searchParams.get('partner')
    if (partner_code && this.$cookies.get('partner_code') !== partner_code) {
      this.$cookies.set('partner_code', partner_code, '10d')
    }

    // 商家資訊
    getShopInfo()
      .then(response => {
        // 比對版本
        const clientVersion = parseFloat(process.env.VUE_APP_VERSION)
        const serverVersion = parseFloat(response.data.data.version)
        if (serverVersion > clientVersion) {
          showNotice('請重新載入頁面 更新程式版本', function() {
            window.location.reload()
          })
        }
        // load the gtm from api
        if (response.data.data.ga_code) gtmInit(response.data.data.ga_code)

        this.$store.commit('SET_INFO', response.data.data)

        // 換版型
        // if (
        //   response.data.data.theme_file !== undefined &&
        //   response.data.data.theme_file !== ''
        // ) {
        //   const file = document.createElement('link')
        //   file.rel = 'stylesheet'
        //   file.href = response.data.data.theme_file
        //   document.head.appendChild(file)
        // }

        const metaDesc = document.createElement('meta')
        metaDesc.name = 'description'
        metaDesc.content = this.$store.getters.shopInfo.description
        document.getElementsByTagName('head')[0].appendChild(metaDesc)

        const metaKeyword = document.createElement('meta')
        metaKeyword.name = 'keyword'
        metaKeyword.content = this.$store.getters.shopInfo.keyword
        document.getElementsByTagName('head')[0].appendChild(metaKeyword)
      })
      .catch(() => {})
  },
  render: h => h(App)
}).$mount('#app')
