import request from '@/services/request'

export function getCatogory() {
  return request({
    url: '/3tCata/getCataList',
    method: 'post'
  })
}
export function getArea() {
  return request({
    url: '/3tArea/getAreaList',
    method: 'post',
    data: {}
  })
}
export function getItemList(data) {
  return request({
    url: '/3tItem/getItemList',
    method: 'post',
    data: data
  })
}
export function getItem(item_id, data) {
  return request({
    url: '/3tItem/getItem/' + item_id,
    method: 'post',
    data: data
  })
}
export function getTagList() {
  return request({
    url: '/3tTag/getTagList',
    method: 'post'
  })
}
export function getBanners(codes) {
  const data = {}
  if (codes !== undefined && codes.trim() !== '') {
    data.codes = codes.trim()
  }

  return request({
    url: '/3tBanner/getBanners',
    method: 'post',
    data: data
  })
}

export function getShopInfo() {
  return request({
    url: '/3tMall/get',
    method: 'get'
  })
}

export function addContact(data) {
  return request({
    url: '/3tContact/add',
    method: 'post',
    data: data
  })
}

export function getPaytype(data) {
  return request({
    url: '/3tPaytype/get',
    method: 'post',
    data: data
  })
}

// 台新人數確認
export function checkRemain(data) {
  return request({
    url: '/3tTscbNft/checkRemain',
    method: 'post',
    data: data
  })
}

// email確認
export function checkEmail(data) {
  return request({
    url: '/3tTscbNft/checkEmail',
    method: 'post',
    data: data
  })
}

// 台新後台檢查訂單
export function checkOrder(data) {
  return request({
    url: '/3tTscbNft/checkOrder',
    method: 'post',
    data: data
  })
}

// 台新後台檢查序號
export function checkNum(data) {
  return request({
    url: '/3tDiscount/checkNum',
    method: 'post',
    data: data
  })
}
