<template>
  <div>
    <!--Loading效果-->
    <loading />
    <!--提醒訊息-->
    <firstloginconfirm />
    <notice />
    <confirm />
    <Cusnotice />
    <router-view v-if="$store.getters.loadCompleted" />
  </div>
</template>
<script>
import Loading from '@/components/loading'
import Notice from '@/components/modal/notice'
import Confirm from '@/components/modal/confirm'
import Cusnotice from '@/components/modal/customnotice'
import firstloginconfirm from '@/components/modal/firstloginconfirm'

export default {
  name: 'App',
  components: {
    Loading,
    Notice,
    Confirm,
    Cusnotice,
    firstloginconfirm
  }
}
</script>

<style lang="scss">
@import 'assets/scss/common.scss';
.allitem_color {
  background-color: $allitem_color !important;
}
.eating_item {
  background-color: $eating_item !important;
}
.hotspring_color {
  background-color: $hotspring_color !important;
}
.living_item {
  background-color: $living_item !important;
}
.spa_item {
  background-color: $spa_item !important;
}
.amusement_item {
  background-color: $amusement_item !important;
}
.traffic_item {
  background-color: $traffic_item !important;
}
.wifi_sim_item {
  background-color: $wifi_sim_item !important;
}
.travil_item {
  background-color: $travil_item !important;
}
</style>
