const search = {
  state: {
    record: {
      data: [],
      page: 1,
      keyword: '',
      cata_id: 0,
      area_id: '',
      scrollY: 0,
      enableAutoLoad: false
    }
  },
  mutations: {
    setSearchRecord: (state, payload) => {
      const { data, page, keyword, area_id, cata_id, scrollY, enableAutoLoad } = payload
      state.record.data = data || []
      state.record.page = page || 1
      state.record.keyword = keyword || ''
      state.record.cata_id = cata_id || 0
      state.record.area_id = area_id || 0
      state.record.scrollY = scrollY || 0
      state.record.enableAutoLoad = enableAutoLoad || false
    }
  },
  actions: {
    clearSearchRecord({ commit }) {
      commit('setSearchRecord', {})
    }
  },
  getters: {
    getSearchRecord(state) {
      return state.record
    }
  }
}

export default search
