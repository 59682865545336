import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { userLogin } from '@/utils/user'
import { showNotice, getApiUrl } from '@/utils/tool'

// create an axios instance
const service = axios.create({
  baseURL: getApiUrl(),
  timeout: 60000, // request timeout
  responseType: 'json'
})
// request interceptor
service.interceptors.request.use(config => {
  // 有token就傳入
  if (store.getters.authInfo.token) {
    config['headers'] = {
      authorization: 'Bearer ' + store.getters.authInfo.token,
      'x-from-uid': store.getters.authInfo.uid
    }
  }

  // if (store.getters.authInfo.uid && store.getters.authInfo.uid !== '') {
  //   config.data = { ...config.data, 'x-from-uid': store.getters.authInfo.uid }
  //   // config.params['x-from-uid'] = store.getters.authInfo.uid
  // }
  // if (store.getters.authInfo.token && store.getters.authInfo.token !== '') {
  //   config.data = { ...config.data, 'Authorization': store.getters.authInfo.token }
  //   // config.params['Authorization'] = store.getters.authInfo.token
  // }

  return config
})

// response interceptor
service.interceptors.response.use(
  function(response) {
    if (response.data == null || response.data.succ === undefined) {
      showNotice('伺服器錯誤', function() {})
    } else {
      responseHandle(response.data, response)
    }
    return response
  },
  error => {
    store.commit('SET_LOADING', false)
    if (error.data) {
      responseHandle(error.data)
    } else {
      showNotice('請檢查您的網路狀態', function() {
        if (router.currentRoute.name === 'error') {
          window.top.location.href = '/'
        } else {
          window.top.location.reload()
        }
      })
    }
    return Promise.reject(error)
  }
)

function responseHandle(responseContent, response) {
  if (!responseContent.succ) {
    const errorCode = responseContent.code + ''
    if (errorCode === '99001') {
      // token ||  若回傳 code = 99001 需轉到 sso
      showNotice(responseContent.msg, function() {
        userLogin()
      })
    } else if (errorCode === 'HG5026') {
      // token ||  若回傳 code = HG5026 需轉到 otp 頁面做身份驗証
      showNotice('請進行OTP驗證', function() {
        router.push({ path: '/user/otp' }).catch(() => {})
      })
    } else if (errorCode.startsWith('10')) {
      if (errorCode === '1020') {
        router.push({ path: '/close' }).catch(() => {})
      } else {
        userLogin(undefined, true)
      }
    } else if (errorCode === '5001') {
      // 未成立訂單不顯示錯誤訊息
    } else if (errorCode === 'HG5015') {
      // TODO
    } else {
      // 其他錯誤訊息
      showNotice(responseContent.msg)
    }
  }
}

export default service
