<template>
  <b-modal
    id="modal-cusnotice"
    :hide-header="true"
    :hide-footer="true"
    modal-class="custommodal"
    @hide="hideModal"
  >
    <template #default>
      <img class="noticeheader" src="@/assets/images/rlnotice_header.png">
      <div class="form-group mb-40">
        <div class="tips_info">
          <span class="media-title">
            最新消息
          </span>
          <span class="media">
            <span
              id="modal_alert_msg"
              class="media-body mb-0 cusbody"
              style="white-space: pre-line"
            >{{ message }}</span>
          </span>
          <img
            src="@/assets/images/rfpromotionimg.gif"
            width="100px"
            height="100px"
            style="display:flex;margin:20px auto"
          >
        </div>
        <div class="tips_info d-flex justify-content-center">
          <button class="promotionbtn" @click="tolink">
            前往登記
          </button>
        </div>
        <div class="remarkarea">
          本案由 3TGDS 與 MaiCoin 集團旗下 NFT 發行平台 Qubic Creator
          合作，提供專屬於您的 NFT
        </div>
      </div>
      <img class="noticefooter" src="@/assets/images/rlnotice_footer.png">
    </template>
  </b-modal>
</template>
<script>
export default {
  name: 'Confirm',
  data() {
    return {}
  },
  computed: {
    message() {
      if (!this.$store.getters.showcusnotice) {
        return ''
      }
      return this.$store.getters.showcusnotice.message
    },
    url() {
      if (!this.$store.getters.showcusnotice) {
        return ''
      }
      return this.$store.getters.showcusnotice.url
    }
  },
  watch: {
    message(newVal, oldVal) {
      if (newVal != null) {
        this.showModal()
      }
    }
  },
  mounted() {
    this.showModal()
  },
  methods: {
    tolink() {
      window.open(this.url, '_blank')
    },
    showModal() {
      if (this.message) {
        this.$bvModal.show('modal-cusnotice')
      }
    },
    hideModal() {
      this.$bvModal.hide('modal-cusnotice')
      this.$store.commit('SET_CUS_NOTICE', null)
    }
  }
}
</script>
<style lang="scss">
.custommodal {
  .modal-body {
    padding: 0 !important;
  }
  .media-title {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }
  .media {
    padding: 0.5rem 3rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .remarkarea {
    color: gray;
    padding: 1rem 3rem;
    font-size: 0.85rem;
  }
}

.promotionbtn {
  letter-spacing: 2px;
  font-weight: 600;
  border-radius: 4px;
  border: none;
  width: 200px;
  height: 40px;
  color: white;
  background: linear-gradient(
    90deg,
    #3f51b1 0%,
    #5a55ae 13%,
    #7b5fac 25%,
    #8f6aae 38%,
    #a86aa4 50%,
    #cc6b8e 62%,
    #f18271 75%,
    #f3a469 87%,
    #f7c978 100%
  ) !important;
}

.modal-content {
  border-radius: 0.7rem !important;
}

.noticeheader {
  width: 100%;
}

.cusbody {
  font-weight: 600;
}
.noticefooter {
  width: 100%;
}
</style>
