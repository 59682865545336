const shop = {
  state: {
    channel: null,
    category: [],
    info: null,
    banner: null,
    cageTitle: []
  },
  mutations: {
    SET_CHANNEL: (state, data) => {
      state.channel = data
    },
    SET_INFO: (state, data) => {
      state.info = data
    },
    SET_CATEGORY: (state, data) => {
      data.push({
        text: '全部分類',
        value: 0
      })
      state.category = data
    },
    SET_BANNER: (state, data) => {
      state.banner = data
    },
    SET_CAGETITLE: (state, data) => {
      state.cageTitle = data
    }
  }
}
export default shop
