<template>
  <div>
    <b-modal
      id="modal_firstloginconfirmDialog"
      ref="modal_firstloginconfirmDialog"
      modal-class="modal_style model_alert"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header="true"
      :hide-footer="true"
      dialog-class="modal-dialog-centered"
      content-class="border-0 rounded-0"
    >
      <div class="border_title clearfix">
        <div class="d-flex justify-content-center">
          <h5 class="mb-0">
            <b>提醒</b>
          </h5>
        </div>
      </div>
      <div class="form-group mb-40">
        <div
          class="tips_info d-flex flex-column justify-content-center align-items-center p-2"
        >
          <div
            class="media d-flex flex-column justify-content-center text-left"
            style="width:100%"
          >
            <i class="icon-error_outline" />
            <span
              id="modal_alert_msg"
              class="media-body mb-0 confirmcontent middletext font-weight-bold"
            >
              {{ message }}
            </span>
            <span
              v-if="submessage"
              id="modal_alert_msg"
              class="media-body mb-0 confirmcontent px-3"
            >
              {{ submessage }}
            </span>
            <span
              v-if="submessage2"
              id="modal_alert_msg"
              class="media-body mb-0 confirmcontent middletext font-weight-bold"
            >
              {{ submessage2 }}
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-around">
        <button
          type="button"
          class="btn btn-reset mr-2 btn-sm"
          @click="hideModal"
        >
          取消
        </button>
        <button type="submit" class="btn btn-next ml-2 btn-sm" @click="confirm">
          同意
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'Confirm',
  computed: {
    message() {
      if (!this.$store.getters.firstloginconfirmDialog) {
        return ''
      }
      return this.$store.getters.firstloginconfirmDialog.message
    },
    submessage() {
      if (!this.$store.getters.firstloginconfirmDialog) {
        return ''
      }
      return this.$store.getters.firstloginconfirmDialog.submessage
    },
    submessage2() {
      if (!this.$store.getters.firstloginconfirmDialog) {
        return ''
      }
      return this.$store.getters.firstloginconfirmDialog.submessage2
    }
  },
  watch: {
    message(newVal, oldVal) {
      if (newVal != null) {
        this.showModal()
      }
    }
  },
  mounted() {
    this.showModal()
  },
  methods: {
    showModal() {
      if (this.message) {
        this.$bvModal.show('modal_firstloginconfirmDialog')
      }
    },
    hideModal() {
      this.$bvModal.hide('modal_firstloginconfirmDialog')
      const dialog = this.$store.getters.firstloginconfirmDialog
      try {
        if (dialog.cancelFunc && typeof dialog.cancelFunc === 'function') {
          dialog.cancelFunc()
        }
      } catch (e) {
        console.log('call cancel function error', e)
      } finally {
        this.$store.commit('SET_FIRSTLOGINCONFIRM_DIALOG', null)
      }
    },
    confirm() {
      this.$bvModal.hide('modal_firstloginconfirmDialog')
      const dialog = this.$store.getters.firstloginconfirmDialog
      try {
        if (dialog.func && typeof dialog.func === 'function') {
          dialog.func()
        }
      } catch (e) {
        console.log('call function error', e)
      } finally {
        this.$store.commit('SET_FIRSTLOGINCONFIRM_DIALOG', null)
      }
    }
  }
}
</script>
<style lang="scss">
@import 'assets/scss/common.scss';
.confirmcontent {
  white-space: pre-line;
}
.middletext {
  font-size: 0.925rem;
}
</style>
