import { getStoreCode } from '@/utils/tool'
// init the script for partner
export function initscriptbypartner() {
  // HGapp 開啟js 加入台新支付APP JS
  if (getStoreCode() === 'happygo' || getStoreCode() === '3t') {
    const icashscript = document.createElement('script')
    icashscript.type = 'text/javascript'
    icashscript.src =
      '/Scripts/happyGoAppjscontext.js?v=' + new Date().getTime()
    document.head.appendChild(icashscript)

    const icashinit = document.createElement('script')
    icashinit.type = 'text/javascript'
    icashinit.src = '/Scripts/happyGoAppinit.js'
    document.body.appendChild(icashinit)
  }
  // icashpay app
  if (getStoreCode() === 'icashpay') {
    const date = new Date().getTime()
    // opapp >>>>>>>>>>>>>>>
    const opappscript = document.createElement('script')
    opappscript.type = 'text/javascript'
    opappscript.src = '/Scripts/opappjscontext.js?v=' + date
    document.head.appendChild(opappscript)

    const opappsinit = document.createElement('script')
    opappsinit.type = 'text/javascript'
    opappsinit.src = '/Scripts/opappinit.js?v=' + date
    document.head.appendChild(opappsinit)
    //  opapp <<<<<<<<<<<<<<<
    // icashapp >>>>>>>>>>>>>>>
    const icashscript = document.createElement('script')
    icashscript.type = 'text/javascript'
    icashscript.src = '/Scripts/icashjscontext.js'
    document.head.appendChild(icashscript)

    const icashinit = document.createElement('script')
    icashinit.type = 'text/javascript'
    icashinit.src = '/Scripts/icashinit.js'
    document.body.appendChild(icashinit)
    // icashapp <<<<<<<<<<<<<<<
  }
}
