<template>
  <div class="wrapper">
    <GoTop />
    <div class="main_area" :class="{ isscroll: isScrolling }">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>

      <!-- footer -->
      <footer
        v-if="$route.name !== 'LoginIndex'"
        class="footer_area"
      >
        <div class="items-group">
          <div class="items">
            <router-link to="/index">
              <div :class="['bg-icon', { active: is_active('index') }]">
                <i :class="['icon', { active: is_active('index') }]" />
              </div>
              <p>首頁</p>
            </router-link>
          </div>
          <div class="items">
            <router-link to="/order">
              <div :class="['bg-icon', { active: is_active('order') }]">
                <i :class="['icon', { active: is_active('order') }]" />
              </div>
              <p>訂單</p>
            </router-link>
          </div>
          <div class="items">
            <router-link to="/voucher">
              <div :class="['bg-icon', { active: is_active('voucher') }]">
                <i :class="['icon', { active: is_active('voucher') }]" />
              </div>
              <p>票夾</p>
            </router-link>
          </div>
          <div class="items">
            <router-link to="/user">
              <div :class="['bg-icon', { active: is_active('user') }]">
                <i :class="['icon', { active: is_active('user') }]" />
              </div>
              <p>帳號</p>
            </router-link>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import GoTop from '@/components/GoTop'
import { getBanners } from '@/api/shop'

export default {
  name: 'Main',
  components: {
    GoTop
  },
  data() {
    return { isScrolling: false, scrolltimer: false }
  },
  watch: {},
  created() {
    window.addEventListener('scroll', this.handlescroll)

    getBanners().then(response => {
      const bannerObj = {}
      response.data.data.forEach(function(item) {
        bannerObj[item.banner_code] = item
      })
      this.$store.commit('SET_BANNER', bannerObj)
    })
  },
  destroyed() {},
  mounted() {},
  methods: {
    is_active(name) {
      if (
        this.$route.meta.menuName !== undefined &&
        this.$route.meta.menuName === name
      ) {
        return true
      } else return false
    },
    handlescroll(event) {
      const self = this
      clearTimeout(self.scrolltimer)
      self.isScrolling = true
      self.scrolltimer = setTimeout(function() {
        self.isScrolling = false
      }, 300)
    }
  }
}
</script>

<style scoped lang="scss">
@import 'assets/scss/common.scss';
.wrapper {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  margin: 0 auto;
  position: relative;
  background: rgba($white-color, $alpha: 1);
  // box-shadow: 0px 0px 200px 0px rgba($main-color, $alpha: 1);
}
.footer_area {
  background-color: $white-color;
  border-top: 1px solid rgba($sec-color, $alpha: 0.2);
  box-shadow: 0px -10px 10px 0px rgba($main-color, $alpha: 0.1);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0 8px;
  z-index: 999;
  transition: opacity 0.6s linear;
  .items-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    .items {
      flex: 0 0 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      i {
        width: 30px;
        height: 30px;
        border-radius: 50px;
        background-color: $white-color;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      p {
        margin: 0;
        padding: 0;
        font-size: 0.8rem;
        color: $font-color;
      }
    }
    .items:nth-child(1) i.icon {
      background-color: $main-color;
      -webkit-mask: url(~@/assets/images/icon-home.svg) no-repeat center center;
      mask: url(~@/assets/images/icon-home.svg) no-repeat center center;
      mask-size: 24px;
    }
    .items:nth-child(2) i.icon {
      background-color: $main-color;
      -webkit-mask: url(~@/assets/images/icon-order.svg) no-repeat center center;
      mask: url(~@/assets/images/icon-order.svg) no-repeat center center;
      mask-size: 24px;
    }
    .items:nth-child(3) i.icon {
      background-color: $main-color;
      -webkit-mask: url(~@/assets/images/icon-ticket.svg) no-repeat center
        center;
      mask: url(~@/assets/images/icon-ticket.svg) no-repeat center center;
      mask-size: 24px;
    }
    .items:nth-child(4) i.icon {
      background-color: $main-color;
      -webkit-mask: url(~@/assets/images/icon-user.svg) no-repeat center center;
      mask: url(~@/assets/images/icon-user.svg) no-repeat center center;
      mask-size: 25px;
    }
    .items i.icon.active,
    .items:nth-child(1) i.icon:hover,
    .items:nth-child(2) i.icon:hover,
    .items:nth-child(3) i.icon:hover,
    .items:nth-child(4) i.icon:hover {
      background-color: $white-color;
      transition: 0.5s;
    }
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $main-color;
    }
    a:hover {
      color: $black-color;
    }
    a:hover i {
      background-color: $notice-color;
    }
    .bg-icon.active,
    .bg-icon:hover {
      width: 30px;
      height: 30px;
      background-color: $notice-color;
      transition: 0.5s;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.isscroll{
  .footer_area {
    opacity: 0;
  }
}
@media screen and (min-width: 640px) {
  .wrapper,
  .footer_area {
    max-width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .wrapper,
  .footer_area {
    max-width: 768px;
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 1s ease;
}

.fade-leave {
}

.fade-leave-active {
  transition: opacity 0.2s ease;
  opacity: 0;
}

.menu_back {
  height: 1000px;
  position: absolute;
  display: block;
  width: 100%;
  top: 270px;
  left: 0;
}
</style>
