<template>
  <div>
    <b-modal
      id="modal_confirm"
      ref="modal_confirm"
      modal-class="modal_style model_alert"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header="true"
      :hide-footer="true"
      dialog-class="modal-dialog-centered"
      content-class="border-0 rounded-1"
    >
      <div class="border_title clearfix">
        <div class="d-flex justify-content-between">
          <h5 class="mb-0">
            <b>提醒</b>
          </h5>
        </div>
      </div>
      <div class="form-group mb-40">
        <div
          class="tips_info d-flex flex-column justify-content-center align-items-center p-2"
        >
          <div class="media" style="width:100%">
            <i class="icon-error_outline" />
            <span id="modal_alert_msg" class="media-body mb-0 confirmcontent">
              {{ message }}
            </span>
          </div>
          <div v-if="warning" class="warningarea d-flex flex-column mt-4">
            <div v-html="warning" />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-next mr-3 btn-sm" @click="confirm">
          {{ btn.length ? btn[0] : '確認' }}
        </button>
        <button
          type="button"
          class="btn btn-reset mr-2 btn-sm ml-3"
          @click="hideModal"
        >
          {{ btn.length ? btn[1] : '取消' }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'Confirm',
  computed: {
    message() {
      if (!this.$store.getters.confirmDialog) {
        return ''
      }
      return this.$store.getters.confirmDialog.message
    },
    warning() {
      if (!this.$store.getters.confirmDialog) {
        return ''
      }
      return this.$store.getters.confirmDialog.warning
    },
    btn() {
      if (!this.$store.getters.confirmDialog) {
        return ''
      }
      return this.$store.getters.confirmDialog.btn
    }
  },
  watch: {
    message(newVal, oldVal) {
      if (newVal != null) {
        this.showModal()
      }
    }
  },
  mounted() {
    this.showModal()
  },
  methods: {
    showModal() {
      if (this.message) {
        this.$bvModal.show('modal_confirm')
      }
    },
    hideModal() {
      this.$bvModal.hide('modal_confirm')
      const dialog = this.$store.getters.confirmDialog
      try {
        if (dialog.cancelFunc && typeof dialog.cancelFunc === 'function') {
          dialog.cancelFunc()
        }
      } catch (e) {
        console.log('call cancel function error', e)
      } finally {
        this.$store.commit('SET_CONFIRM_DIALOG', null)
      }
    },
    confirm() {
      this.$bvModal.hide('modal_confirm')
      const dialog = this.$store.getters.confirmDialog
      try {
        if (dialog.func && typeof dialog.func === 'function') {
          dialog.func()
        }
      } catch (e) {
        console.log('call function error', e)
      } finally {
        this.$store.commit('SET_CONFIRM_DIALOG', null)
      }
    }
  }
}
</script>
<style lang="scss">
@import 'assets/scss/common.scss';
.confirmcontent {
  white-space: pre-line;
  font-weight: 500;
}
.warningarea {
  font-weight: 500;
  white-space: pre-line;
}
.warning {
  color: $warning-color;
}
</style>
