const state = {
  noticeMessage: null,
  errorMessage: null,
  loadCompleted: false,
  isLoading: true,
  confirmDialog: {
    message: null,
    func: null,
    cancelFunc: null,
    warning: [],
    btn: []
  },
  firstloginconfirmDialog: {
    message: null,
    submessage: null,
    submessage2: null,
    func: null,
    cancelFunc: null
  },
  showbackbtn: true,
  showbanner: { status: false, date: '' },
  cusnotice: { message: null, url: null, func: null },
  isinapp: null
}

const mutations = {
  SET_LOAD_COMPLETED(state, data) {
    state.loadCompleted = data
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_NOTICE_MESSAGE(state, message) {
    state.noticeMessage = message
  },
  SET_ERROR_MESSAGE(state, message) {
    state.errorMessage = message
  },
  SET_CONFIRM_DIALOG(state, confirm) {
    state.confirmDialog = confirm
  },
  SET_FIRSTLOGINCONFIRM_DIALOG(state, confirm) {
    state.firstloginconfirmDialog = confirm
  },
  SET_ALERT(state, alert) {
    state.alert = alert
  },
  SET_SHOW_BACKBTN(state, data) {
    state.showbackbtn = data
  },
  SET_SHOW_BANNER(state, data) {
    state.showbanner = data
  },
  SET_CUS_NOTICE(state, data) {
    state.cusnotice = data
  },
  SET_IN_APP(state, data) {
    state.isinapp = data
  }
}

const actions = {}

export default {
  state,
  mutations,
  actions
}
