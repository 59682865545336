import request from '@/services/request'
import requestPure from '@/services/requestPure'

export function loginByUid(uid) {
  return request({
    url: '/3tLogin/login',
    method: 'post',
    data: {
      type: 'happygo',
      user: uid
    }
  })
}
export function loginBySSO(ssoToken, type) {
  type = type || 'happygo'
  return request({
    url: '/3tLogin/login',
    method: 'post',
    data: {
      type: type,
      token: ssoToken
    }
  })
}
export function loginBySSO2(body) {
  return request({
    url: '/3tLogin/sso',
    method: 'post',
    data: body
  })
}
export function logout() {
  return request({
    url: '/3tLogin/logout',
    method: 'post'
  })
}
export function resetToken() {
  return requestPure({
    url: '/3tLogin/resetToken',
    method: 'post'
  })
}
export function getMember() {
  return request({
    url: '/3tMember/getMember',
    method: 'post'
  })
}
export function editMember(data) {
  return request({
    url: '/3tMember/edit',
    method: 'post',
    data: data
  })
}
export function sendOTPSms(mobile, id_no) {
  return request({
    url: '/3tHappygo/sendOTPSms',
    method: 'post',
    data: {
      mobile: mobile,
      id_no: id_no
    }
  })
}
export function checkOTPSimpleIdNo(caseid, otpcode) {
  return request({
    url: '/3tHappygo/checkOTPSimpleIdNo',
    method: 'post',
    data: {
      caseid: caseid,
      otpcode: otpcode
    }
  })
}

export function getPoint() {
  return request({
    url: '/3tMember/getPoint',
    method: 'post'
  })
}

// 第一次登入並同意
export function updFirstPopup() {
  return request({
    url: '/3tMember/updFirstPopup',
    method: 'post'
  })
}
