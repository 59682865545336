import jwt_decode from 'jwt-decode'

const user = {
  state: {
    memberInfo: null,
    authInfo: {
      token: null,
      uid: null,
      third_verify: false
    },
    refreshTime: 0,
    logintype: null,
    canlogout: true,
    icashpayapp: null
  },
  mutations: {
    SET_MEMBER_INFO: (state, data) => {
      if (data === null) {
        state.memberInfo = null
      } else {
        state.memberInfo = Object.assign({}, state.memberInfo, data)
      }
    },
    SET_THIRD_VERIFY: (state, data) => {
      state.authInfo.third_verify = data
    },
    SET_AUTH_INFO: (state, jwt) => {
      if (!jwt) {
        state.authInfo.token = null
        state.authInfo.uid = null
        state.authInfo.third_verify = false
      } else {
        const jwtInfo = jwt_decode(jwt)

        state.authInfo.token = jwt
        state.authInfo.uid = jwtInfo._from_uid
        state.authInfo.third_verify = !jwtInfo.is_verify
      }
    },
    SET_REFRESH_TIME: (state, data) => {
      state.refreshTime = data
    },
    SET_LOGIN_TYPE: (state, data) => {
      state.logintype = data
    },
    SET_POINTS: (state, data) => {
      state.memberInfo.point = data
    },
    SET_LOGOUT_AUTH: (state, data) => {
      state.canlogout = data
    },
    SET_ICASH_PAYURL: (state, data) => {
      state.icashpayapp = data
    }
  },
  actions: {
    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('SET_MEMBER_INFO', null)
        commit('SET_REFRESH_TIME', 0)
        commit('SET_LOGIN_TYPE', null)
        state.authInfo.token = null
        state.authInfo.uid = null
        state.authInfo.third_verify = false

        resolve()
      })
    }
  }
}

export default user
