/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Router from 'vue-router'
import Main from '@/views/layout/Main'
import Blank from '@/views/layout/Blank'
import store from '@/store'

Vue.use(Router)

export const routerMap = [
  {
    path: '',
    redirect: '/index'
  },
  {
    path: '/',
    redirect: '/index'
  },
  {
    component: Main,
    path: '/',
    children: [
      {
        path: 'index',
        component: () => import('@/views/index'),
        meta: { needLogin: false, menuName: 'index' },
        name: 'Index'
      }
    ]
  },
  {
    component: Main,
    path: '/user',
    children: [
      {
        path: '',
        redirect: 'index'
      },
      {
        path: 'index',
        component: () => import('@/views/user/index'),
        meta: { needLogin: false, menuName: 'user', title: '帳號' },
        name: 'UserIndex'
      },
      {
        path: 'info',
        component: () => import('@/views/user/info'),
        meta: { needLogin: true, menuName: 'user', title: '個人資訊' },
        name: 'UserInfo'
      },
      {
        path: 'edit',
        component: () => import('@/views/user/edit'),
        meta: { needLogin: true, menuName: 'user', title: '編輯個人資訊' },
        name: 'UserEdit'
      },
      {
        path: 'cancel-policy',
        component: () => import('@/views/user/cancel_policy'),
        meta: { needLogin: false, menuName: 'user', title: '取消政策' },
        name: 'UserCancelPolicy'
      },
      {
        path: 'person-policy',
        component: () => import('@/views/user/person_policy'),
        meta: { needLogin: false, menuName: 'user', title: '隱私權政策' },
        name: 'UserPersonPolicy'
      },
      {
        path: 'about',
        component: () => import('@/views/user/about'),
        meta: { needLogin: false, menuName: 'user', title: '關於我們' },
        name: 'UserAbout'
      },
      {
        path: 'faq',
        component: () => import('@/views/user/faq'),
        meta: { needLogin: false, menuName: 'faq', title: '常見問答' },
        name: 'UserFaq'
      },
      {
        path: 'otp',
        component: () => import('@/views/user/otp'),
        meta: {},
        name: 'Otp'
      },
      {
        path: 'hgsso/:redirect?',
        component: () => import('@/views/user/happygo_sso'),
        meta: { needLogin: false },
        name: 'HappyGoSSO'
      },
      {
        path: 'hgsso-return/:redirect?',
        component: () => import('@/views/user/happygo_sso_return'),
        meta: { needLogin: false },
        name: 'HappyGoSSOReturn'
      },
      {
        path: 'sso',
        component: () => import('@/views/user/sso'),
        meta: { needLogin: false },
        name: 'SSO'
      },
      {
        path: 'sso-return',
        component: () => import('@/views/user/sso_return'),
        meta: { needLogin: false },
        name: 'SSOReturn'
      },
      {
        path: 'sso-accept',
        component: () => import('@/views/user/sso_accept'),
        meta: { needLogin: false },
        name: 'SSOAccept'
      },
      {
        path: 'login',
        component: () => import('@/views/user/login'),
        meta: {},
        name: 'Login'
      },
      {
        path: 'loginindex/:redirect?',
        component: () => import('@/views/user/loginindex'),
        meta: { needLogin: false },
        name: 'LoginIndex'
      },
      {
        path: 'prompt',
        component: () => import('@/views/user/prompt'),
        meta: { needLogin: false, title: '登入提示' },
        name: 'Prompt'
      }
    ]
  },
  {
    component: Main,
    path: '/product',
    children: [
      {
        path: '',
        component: () => import('@/views/product/index'),
        meta: { needLogin: false, menuName: 'product' },
        name: 'ProductIndex',
        beforeEnter: (to, from, next) => {
          // 若不是從明細頁返回, 清除搜尋紀錄
          if (from.name !== 'ProductDetail') {
            (async() => {
              await store.dispatch('clearSearchRecord')
            })()
          }
          next()
        }
      },
      {
        path: 'keyword/:keyword',
        component: () => import('@/views/product/index'),
        meta: { needLogin: false, menuName: 'index' },
        name: 'ProductKeyword',
        beforeEnter: (to, from, next) => {
          // 若不是從明細頁返回, 清除搜尋紀錄
          if (from.name !== 'ProductDetail') {
            (async() => {
              await store.dispatch('clearSearchRecord')
            })()
          }
          next()
        }
      },
      {
        path: 'cate/:cate',
        component: () => import('@/views/product/index'),
        meta: { needLogin: false, menuName: 'index' },
        name: 'ProductCate',
        beforeEnter: (to, from, next) => {
          // 若不是從明細頁返回, 清除搜尋紀錄
          if (from.name !== 'ProductDetail') {
            (async() => {
              await store.dispatch('clearSearchRecord')
            })()
          }
          next()
        }
      },
      {
        path: 'area/:area',
        component: () => import('@/views/product/index'),
        meta: { needLogin: false, menuName: 'index' },
        name: 'ProductArea',
        beforeEnter: (to, from, next) => {
          // 若不是從明細頁返回, 清除搜尋紀錄
          if (from.name !== 'ProductDetail') {
            (async() => {
              await store.dispatch('clearSearchRecord')
            })()
          }
          next()
        }
      },
      {
        path: 'detail/:id?/:cate?',
        component: () => import('@/views/product/detail'),
        meta: { needLogin: false, menuName: 'index' },
        name: 'ProductDetail'
      }
    ]
  },
  {
    component: Main,
    path: '/order',
    children: [
      {
        path: '',
        redirect: 'index'
      },
      {
        path: 'index',
        component: () => import('@/views/order/index'),
        meta: { needLogin: true, menuName: 'order', title: '訂單列表' },
        name: 'OrderIndex'
      },
      {
        path: 'detail/:num?',
        component: () => import('@/views/order/detail'),
        meta: { menuName: 'order', title: '訂單內容' },
        name: 'OrderDetail'
      },
      {
        path: 'init',
        component: () => import('@/views/order/init'),
        meta: { menuName: 'order', title: '訂購確認' },
        name: 'OrderInit'
      },
      {
        path: 'user-info',
        component: () => import('@/views/order/user-info'),
        meta: { menuName: 'order', title: '訂購填寫' },
        name: 'OrderUserInfo'
      },
      {
        path: 'finish',
        component: () => import('@/views/order/finish'),
        meta: { menuName: 'order', title: '訂購完成' },
        name: 'OrderFinish'
      },
      {
        path: 'fail',
        component: () => import('@/views/order/fail'),
        meta: { menuName: 'order' },
        name: 'OrderFail'
      },
      {
        path: 'qrcode/:code?/:payment?/:id?',
        component: () => import('@/views/order/paymentqrcode'),
        meta: { menuName: 'qrcode' },
        name: 'qrcodeView'
      },
      {
        path: 'checkPaid',
        component: () => import('@/views/order/checkPaid'),
        meta: { menuName: 'order' },
        name: 'checkPaid'
      }
    ]
  },
  {
    // 票券
    component: Main,
    path: '/voucher',
    children: [
      {
        path: '',
        redirect: 'index'
      },
      {
        path: 'index',
        component: () => import('@/views/voucher/index'),
        meta: { needLogin: true, menuName: 'voucher', title: '票夾列表' },
        name: 'Voucher'
      },
      {
        path: 'mode/:mode/:cate?',
        component: () => import('@/views/voucher/index'),
        meta: { needLogin: true, menuName: 'voucher' },
        name: 'VoucherMode'
      },
      {
        path: 'detail/:num?/:cate?',
        component: () => import('@/views/voucher/detail'),
        meta: { menuName: 'voucher', title: '票券內容' },
        name: 'VoucherDetail'
      }
    ]
  },
  {
    component: Main,
    path: '/contact',
    children: [
      {
        path: '',
        redirect: 'ask'
      },
      {
        path: 'ask/:order_num?',
        component: () => import('@/views/contact/ask'),
        meta: { menuName: 'user', title: '聯絡我們' },
        name: 'ContactAsk'
      }
    ]
  },
  {
    path: '/close',
    component: () => import('@/views/close'),
    name: 'Close'
  },
  {
    path: '/error',
    component: () => import('@/views/error'),
    name: 'Error'
  },
  {
    path: '*',
    redirect: '/error',
    hidden: true
  }
]

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0, behavior: 'instant' }),
  routes: routerMap
})
