import store from 'vuex-store/index'
import router from '@/router'
import { showNotice, getStoreCode } from '@/utils/tool'
import { resetToken, getPoint } from '@/api/user'

let refreshFlag = false

export function siteLogin(res, redirectPath) {
  if (redirectPath === undefined) {
    redirectPath = '/index'
  }
  if (
    res.data &&
    res.headers.autherization !== undefined &&
    res.data.data.uid.trim() !== ''
  ) {
    try {
      store.commit('SET_AUTH_INFO', res.headers.autherization)
      setupRefreshToken()

      router.push({ path: redirectPath }).catch(() => {})
    } catch (error) {
      showNotice('帳號登入異常 ' + error.message, function() {
        router.push({ path: redirectPath }).catch(() => {})
      })
    }
  } else {
    showNotice('帳號登入異常 Token異常', function() {
      router.push({ path: redirectPath }).catch(() => {})
    })
  }
}

export function setupRefreshToken() {
  if (store.getters.authInfo.token && store.getters.authInfo.uid) {
    if (
      store.getters.refreshTime === 0 ||
      store.getters.refreshTime === undefined
    ) {
      store.commit(
        'SET_REFRESH_TIME',
        Math.floor(Date.now() / 1000) +
          parseInt(process.env.VUE_APP_REFRESH_TOKEN_SECOND)
      )
    }
    refreshFlag = window.setInterval(function() {
      if (!store.getters.authInfo.token && !store.getters.authInfo.uid) {
        clearInterval(refreshFlag)
      } else if (store.getters.refreshTime <= Math.floor(Date.now() / 1000)) {
        store.commit(
          'SET_REFRESH_TIME',
          Math.floor(Date.now() / 1000) +
            parseInt(process.env.VUE_APP_REFRESH_TOKEN_SECOND)
        )
        resetToken()
          .then(response => {
            if (!response.data.succ) {
              // refresh失敗 登出
              siteLogout(function() {
                router.push({ path: '/index' }).catch(() => {})
              })
            } else {
              // refresh成功 更新refreshtime + token
              store.commit(
                'SET_REFRESH_TIME',
                Math.floor(Date.now() / 1000) +
                  parseInt(process.env.VUE_APP_REFRESH_TOKEN_SECOND)
              )
              store.commit('SET_AUTH_INFO', response.headers.autherization)
              // 更新點數
              updatePoint()
            }
          })
          .catch(() => {
            // refresh異常 登出
            siteLogout(function() {
              router.push({ path: '/index' }).catch(() => {})
            })
          })
      }
    }, 10000)
  } else {
    store.commit('SET_REFRESH_TIME', 0)
    clearInterval(refreshFlag)
  }
}

export function siteLogout(callback) {
  store.dispatch('LogOut').then(() => {
    setupRefreshToken()
    callback()
  })

  // logout().then((response) => {
  //   if (callback !== undefined) {
  //     store.dispatch('LogOut').then(() => {
  //       callback()
  //     })
  //   }
  // }).catch(() => {
  // })
}

// 判斷是導向happygo sso或是站內登入  tokenexpired=>token expired
export function userLogin(toRoute, tokenexpired) {
  let toPath = router.currentRoute.path
  if (toRoute !== undefined) {
    toPath = toRoute.path
  }
  if (getStoreCode() === 'happygo') {
    router
      .push({ path: '/user/hgsso/' + Buffer.from(toPath).toString('base64') })
      .catch(() => {})
  } else if (getStoreCode() === 'everrich') {
    router.push({ path: '/user/prompt' }).catch(() => {})
  } else if (getStoreCode() === 'icashpay') {
    if (tokenexpired && store.getters.authInfo.token) {
      // token is expired and close the webview in icash app
      showNotice('帳號逾時!', function() {
        if (store.getters.isinapp.name !== 'icash') {
          window.OPAPPJsInterface.call('OPCloseWebview', null)
        } else window.iCashJSContext.call('CloseWeb')
      })
    } else {
      router.push({ path: '/user/prompt' }).catch(() => {})
    }
  } else {
    router
      .push({
        path: '/user/loginindex/' + Buffer.from(toPath).toString('base64')
      })
      .catch(() => {})
  }
}

export function updatePoint() {
  getPoint().then(res => {
    if (res.data.code === 0 && res.data.data.point !== null) {
      store.commit('SET_POINTS', res.data.data.point)
    }
  })
}
// point check
export function pointcheck(itempoint) {
  const res = { needcheck: false, enough: true }
  if (itempoint && getStoreCode() === 'everrich') {
    res.needcheck = !!store.getters.authInfo.token
    if (
      res.needcheck &&
      store.getters.memberInfo &&
      store.getters.memberInfo.point !== undefined &&
      itempoint > store.getters.memberInfo.point
    ) {
      res.enough = false
    }
  }
  return res
}
