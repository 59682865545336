import Vue from 'vue'
import moment from 'moment'

Vue.filter('priceFormat', function(value) {
  if (value === '-') {
    return '-'
  }
  if (typeof value === 'string') {
    value = parseInt(value)
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})

Vue.filter('dateFormat', function(value) {
  if (value instanceof Date) {
    return moment(value).format('YYYY/MM/DD')
  } else {
    const microsecond = value * 1000
    const date = new Date(microsecond)
    return moment(date).format('YYYY/MM/DD')
  }
})

Vue.filter('dateTimeFormat', function(value) {
  if (value instanceof Date) {
    return moment(value).format('MM/DD HH:mm')
  } else {
    const microsecond = value * 1000
    const date = new Date(microsecond)
    return moment(date).format('MM/DD HH:mm')
  }
})

Vue.filter('fullDateTimeFormat', function(value) {
  if (value instanceof Date) {
    return moment(value).format('YYYY-MM-DD HH:mm:ss')
  } else {
    const microsecond = value * 1000
    const date = new Date(microsecond)
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  }
})

Vue.filter('FundsTimeFormat', function(value) {
  if (value instanceof Date) {
    return moment(value).format('YYYY-MM-DD HH:mm:ss')
  } else {
    const microsecond = value * 1000
    const date = new Date(microsecond)
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  }
})

Vue.filter('productDetailFormat', function(value, className) {
  return value
    .replace(new RegExp('【', 'gm'), '<div class="' + className + '">【')
    .replace(new RegExp('】', 'gm'), '】</div>')
})

Vue.filter('pricename', function(value) {
  if (value && value !== '單一價') return '-' + value
})
