<template>
  <div>
    <b-modal
      id="modal_alert"
      ref="modal_alert"
      scrollable
      title="提醒"
      ok-only
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <div class="main py-3 nl2br" v-text="message" />
      <template #modal-footer="{ hide }">
        <b-button size="sm" variant="outline-secondary" @click="submit()">
          確定
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'Notice',
  computed: {
    message() {
      if (!this.$store.getters.noticeMessage) {
        return null
      }

      return this.$store.getters.noticeMessage.message
    }
  },
  watch: {
    message(newVal, oldVal) {
      if (newVal === 'closeModal') {
        this.hideModal()
      } else if (newVal != null) {
        this.showModal()
      }
    }
  },
  mounted() {
    this.showModal()
  },
  methods: {
    showModal() {
      if (this.message) {
        this.$bvModal.show('modal_alert')
      }
    },
    hideModal() {
      this.$bvModal.hide('modal_alert')
      this.$store.commit('SET_NOTICE_MESSAGE', null)
    },
    submit() {
      const func = this.$store.getters.noticeMessage.func
      const close = this.$store.getters.noticeMessage.close

      if (close) {
        this.hideModal()
      }

      if (func !== undefined && typeof func === 'function') {
        func()
      }
    }
  }
}
</script>

<style>
  #modal_alert___BV_modal_outer_{
    z-index: 100000 !important;
  }
</style>
