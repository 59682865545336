import axios from 'axios'
import store from '@/store'
import { getApiUrl } from '@/utils/tool'

// create an axios instance
const servicePure = axios.create({
  baseURL: getApiUrl(),
  timeout: 60000, // request timeout
  responseType: 'json'
})
// request interceptor
servicePure.interceptors.request.use(
  config => {
    // 有token就傳入
    if (store.getters.authInfo.token) {
      config['headers'] = {
        authorization: 'Bearer ' + store.getters.authInfo.token,
        'x-from-uid': store.getters.authInfo.uid
      }
    }

    // if (store.getters.authInfo.uid && store.getters.authInfo.uid !== '') {
    //   config.data = { ...config.data, 'x-from-uid': store.getters.authInfo.uid }
    //   // config.params['x-from-uid'] = store.getters.authInfo.uid
    // }
    // if (store.getters.authInfo.token && store.getters.authInfo.token !== '') {
    //   config.data = { ...config.data, 'Authorization': store.getters.authInfo.token }
    //   // config.params['Authorization'] = store.getters.authInfo.token
    // }

    return config
  },
)
export default servicePure
