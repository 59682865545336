const getters = {
  channel: state => state.shop.channel,
  shopInfo: state => state.shop.info,
  banner: state => state.shop.banner,
  cageTitle: state => state.shop.cageTitle,
  memberInfo: state => state.user.memberInfo,
  authInfo: state => state.user.authInfo,
  logintype: state => state.user.logintype,
  canlogout: state => state.user.canlogout,
  icashpayapp: state => state.user.icashpayapp,
  orderInfo: state => state.order.info,
  orderItem: state => state.order.item,
  orderHappyGoId: state => state.order.orderHappyGoId,
  refreshTime: state => state.user.refreshTime,
  isLoading: state => state.app.isLoading,
  confirmDialog: state => state.app.confirmDialog,
  firstloginconfirmDialog: state => state.app.firstloginconfirmDialog,
  loadCompleted: state => state.app.loadCompleted,
  noticeMessage: state => state.app.noticeMessage,
  errorMessage: state => state.app.errorMessage,
  showbackbtn: state => state.app.showbackbtn,
  showbanner: state => state.app.showbanner,
  showcusnotice: state => state.app.cusnotice,
  isinapp: state => state.app.isinapp

}
export default getters
