const defaultItem = {
  'item_id': null,
  'plans': [{
    'plan_id': null,
    'prices': [{
      'price_id': null,
      'sale_price': null,
      'qty': 1,
      'point': null,
      'point_id': null
    }]
  }]
}
const defaultInfo = {
  'partner': null,
  'trans_point': 0,
  'trans_amount': 0,
  'paytype_id': 1,
  'mobile': null,
  'id_no': null,
  'bill_name': null,
  'bill_phone': null,
  'bill_email': null,
  'inv_type': 1,
  'inv_uni_num': '',
  'inv_uni_title': '',
  'inv_donate': '',
  'remark': '',
  'is_use_member': 'f',
  'items': []
}

const order = {
  state: {
    info: defaultInfo,
    item: defaultItem,
    orderHappyGoId: null
  },
  mutations: {
    SET_PAYMENT_ORDER_ID: (state, data) => {
      state.orderHappyGoId = data
    },
    SET_DEFAULT_INFO: (state) => {
      state.info = defaultInfo
    },
    SET_INFO: (state, data) => {
      state.info = data
    },
    SET_DEFAULT_ITEM: (state) => {
      state.item = defaultItem
    },
    SET_ITEM: (state, data) => {
      state.item = data
    }
  }
}
export default order
